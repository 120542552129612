import { Article } from "components/Article";
import { CtaButton } from "components/CtaButton";
import { SectionHeading } from "components/SectionHeading";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React, { useEffect } from "react";

export default function News({ data }: PageProps<Queries.NewsQuery>) {
	const { navigate, t } = useI18next();

	useEffect(() => {
		if (data.locales && !data.news) navigate("/404");
	}, [data]);

	if (!data.news) return null;

	return (
		<div className="pt-12 pb-24 text-white lg:pt-20 lg:pb-72">
			<div className="mx-auto">
				<SectionHeading className="justify-center mb-12 w-full lg:mb-28">
					{t("title.news")}
				</SectionHeading>
				<div className="p-4 mx-auto max-w-5xl bg-article-pattern">
					<div className="mx-auto max-w-[700px] lg:pt-12">
						<time className="block text-pallet-sky">
							{data?.news?.frontmatter?.date}
						</time>
						<h3 className="mt-1 mb-3 text-2xl font-bold lg:mt-2 lg:mb-7">
							{data?.news?.frontmatter?.title}
						</h3>
						{data?.news?.frontmatter?.cover?.childImageSharp
							?.gatsbyImageData && (
							<GatsbyImage
								alt="Cover image"
								image={
									data.news.frontmatter.cover.childImageSharp.gatsbyImageData
								}
							/>
						)}
						<article>
							<Article
								className="pt-5 pb-12 mb-12 lg:pb-16"
								dangerouslySetInnerHTML={{
									__html: data?.news?.html ?? "",
								}}
							/>
						</article>
					</div>
				</div>
				<div className="pt-10 text-center lg:pt-28">
					<CtaButton to={Links.Announcements}>
						{t("button.back_to_list")}
					</CtaButton>
				</div>
			</div>
		</div>
	);
}

export const query = graphql`
	query News($language: String!, $frontmatter__slug: String) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		news: markdownRemark(
			frontmatter: {
				slug: { eq: $frontmatter__slug }
				type: { eq: "announcement" }
			}
			fields: { language: { eq: $language } }
		) {
			id
			html
			frontmatter {
				title
				cover {
					childImageSharp {
						gatsbyImageData(width: 960, placeholder: BLURRED)
					}
				}
				date(formatString: "YYYY.MM.DD")
			}
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
